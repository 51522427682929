import { TooltipProps } from "recharts";
import {
  Card,
  withStyles,
  WithStyles,
  Theme,
  Typography
} from "@material-ui/core";
import React from "react";

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(1)
  }
});

interface Props extends TooltipProps, WithStyles<typeof styles> {}

export class CustomTooltip extends React.Component<Props> {
  render() {
    const { payload, classes, formatter, label, labelFormatter } = this.props;

    if (!payload || payload.length === 0) {
      return null;
    }

    return (
      <Card className={classes.root}>
        <Typography color="primary">{labelFormatter(label)}</Typography>
        {(payload || []).map((payload, idx) =>
          formatter
            ? formatter(payload.value, payload.name, payload, idx)
            : payload.value
        )}
      </Card>
    );
  }
}

const TooltipWithStyles = withStyles(styles)(CustomTooltip);
export default (props: TooltipProps) => <TooltipWithStyles {...props} />;
