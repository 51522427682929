import React from "react";
import Dashboard from "../components/dashboard/Dashboard";
import PrivateRoute from "../components/auth/PrivateRoute";

class HomePage extends React.Component {
  render() {
    return <PrivateRoute component={Dashboard} />;
  }
}

export default HomePage;
