import * as React from "react";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import Charts from "./Charts";
import Compliance from "./Compliance";
import { RouteComponentProps } from "@reach/router";
import PaddedLayout from "../common/PaddedLayout";
import PageTitleBar from "../common/header/PageTitleBar";
import PageStepper from "../common/header/PageStepper";

const styles = (theme: Theme) =>
  createStyles({
    actions: {
      padding: theme.spacing(1)
    },
    link: {
      "&:visited": {
        color: "#0000FF"
      }
    },
    appRow: {
      cursor: "pointer"
    }
  });

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{}> {}

class Dashboard extends React.Component<Props> {
  render() {
    return (
      <React.Fragment>
        <PageStepper />
        <PageTitleBar title="Dashboard" />
        <PaddedLayout>
          <Charts />
          <Compliance linkToArcadia />
        </PaddedLayout>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Dashboard);
