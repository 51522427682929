import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql, ChildProps } from "react-apollo";
import moment from "moment";
import { gradients, colors } from "../../Theme";
import DashboardGraph from "./DashboardGraph";
import { withStyles, WithStyles, Theme } from "@material-ui/core";
import { FundBalance } from "../../graphql/FundBalance";
import { withDataset, WithDataset } from "../auth/withDataset";

export const FUND_BALANCE_QUERY = gql`
  query FundBalance($start: String, $end: String) {
    balance(startDate: $start, endDate: $end, pageSize: 31) {
      effectiveDate
      balance {
        amount
      }
    }

    members(startDate: $start, endDate: $end, pageSize: 31) {
      effectiveDate
      members {
        active
        inactive
      }
    }
  }
`;

export const FUND_BALANCE_VARIABLES = {
  start: moment()
    .subtract(4, "week")
    .format("YYYY-MM-DD"),
  end: "today"
};

const styles = (theme: Theme) => ({
  root: {
    width: "100%",
    "&>*": {
      display: "inline-block",
      width: `calc(50% - ${theme.spacing(1)}px)`,
      marginLeft: theme.spacing(2),
      "&:first-child": {
        marginLeft: 0
      }
    }
  }
});

interface Props
  extends WithStyles<typeof styles>,
    ChildProps<{}, FundBalance>,
    WithDataset {}

export class Charts extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.sandboxDataset !== this.props.sandboxDataset) {
      // tslint:disable-next-line: no-floating-promises
      this.props.data.refetch();
    }
  }

  render() {
    const { loading, balance, members } = this.props.data;
    return (
      <div className={this.props.classes.root}>
        <DashboardGraph
          title="Assets"
          data={!loading && balance}
          background={gradients.header}
          foreground="#FFFFFF"
          contrast="#FFFFFF"
          series={[
            {
              name: "Balance",
              property: "balance.amount",
              color: "#ffffff"
            }
          ]}
          format={value => `$${Math.floor((value as number) / 1000000)}m`}
        />
        <DashboardGraph
          title="Members"
          data={!loading && members}
          background="#FFFFFF"
          foreground={colors.primary}
          contrast="#000000"
          series={[
            {
              name: "Active members",
              property: "members.active",
              color: colors.primary
            }
          ]}
          format={value => value.toLocaleString()}
        />
      </div>
    );
  }
}

export default withDataset(
  graphql<{}, FundBalance>(FUND_BALANCE_QUERY, {
    options: () => ({
      variables: FUND_BALANCE_VARIABLES
    })
  })(withStyles(styles)(Charts))
);
