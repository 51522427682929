import React, { Component } from "react";
import {
  Card,
  Theme,
  withStyles,
  WithStyles,
  CardHeader,
  Typography,
  createStyles
} from "@material-ui/core";
import {
  LineChart,
  ResponsiveContainer,
  Line,
  XAxis,
  Tooltip,
  YAxis,
  CartesianGrid,
  AxisDomain
} from "recharts";
import moment from "moment";
import CustomTooltip from "../common/ChartTooltip";
import _ from "lodash";
import CenteredProgress from "../common/CenteredProgress";
import { gradients } from "../../Theme";
import Color from "color";
import cx from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(2),
      background: gradients.header,
      boxShadow: `0px 2px 15px rgba(0, 0, 0, 0.1)`,
      height: 250
    },
    cardAction: {
      marginTop: 0
    },
    title: { fontSize: 18, fontWeight: 500 },
    tooltip: { padding: theme.spacing(1) },
    progress: { color: "#ffffff" },
    periodSelector: {
      display: "flex",
      padding: [[theme.spacing(1) / 2, theme.spacing(1)]] as any,
      borderRadius: theme.shape.borderRadius / 2,
      marginRight: theme.spacing(2)
    },
    period: {
      marginRight: theme.spacing(1) / 2,
      marginLeft: theme.spacing(1) / 2,
      fontSize: 11,
      fontWeight: 500,
      color: theme.palette.grey[700],
      cursor: "pointer",

      "&$periodActive": {
        color: theme.palette.primary.main
      }
    },
    periodActive: {},
    bigNumber: {
      opacity: 0.9,
      fontWeight: 200,
      fontSize: 28,
      marginTop: -theme.spacing(2),
      marginLeft: theme.spacing(3)
    },
    gain: {
      opacity: 0.8,
      fontWeight: 500
    },
    dateRange: {
      opacity: 0.5,
      fontWeight: 500
    }
  });

interface Series {
  property: string;
  name: string;
  color: string;
}

interface Props<T extends { effectiveDate: string }>
  extends WithStyles<typeof styles> {
  title: string;
  background: string;
  foreground: string;
  contrast: string;
  series: Series[];
  data?: T[];
  format: (value: number | string | (number | string)[]) => string;
}

const labelFormatter = (tick: string) => moment(tick).format("MMM D");
const formatSubheader = (date: string) => moment(date).format("MMM D YYYY");

export class FundBalanceChart<
  T extends { effectiveDate: string }
> extends Component<Props<T>> {
  render() {
    const {
      classes,
      series,
      background,
      foreground,
      contrast,
      data,
      title,
      format
    } = this.props;

    const subHeader =
      data && data.length > 0
        ? `${formatSubheader(data[0].effectiveDate)} - ${formatSubheader(
            data[data.length - 1].effectiveDate
          )}`
        : "";

    const bigNumberProperty = series[0].property;

    const bigNumber =
      data && data.length > 0
        ? format(_.get(data[data.length - 1] as any, bigNumberProperty))
        : "";

    const gain =
      data && data.length > 0
        ? `+${format(
            _.get(data[data.length - 1] as any, bigNumberProperty) -
              _.get(data[0] as any, bigNumberProperty)
          )}`
        : "";

    const PeriodSelector = (
      <Card className={classes.periodSelector}>
        <Typography className={classes.period}>1d</Typography>
        <Typography className={cx(classes.period, classes.periodActive)}>
          4w
        </Typography>
        <Typography className={classes.period}>1y</Typography>
        <Typography className={classes.period}>Mtd</Typography>
        <Typography className={classes.period}>Qtd</Typography>
        <Typography className={classes.period}>Ytd</Typography>
      </Card>
    );

    const minValue = _.get((data || [])[0], bigNumberProperty, 100000);
    const domain: [AxisDomain, AxisDomain] = [
      `dataMin - ${minValue / 10}`,
      "auto"
    ];

    return (
      <Card className={classes.card} style={{ background }}>
        <CardHeader
          classes={{
            title: classes.title,
            action: classes.cardAction
          }}
          title={title}
          subheader={
            <React.Fragment>
              <span className={classes.dateRange} style={{ color: contrast }}>
                {subHeader}
              </span>{" "}
              <span className={classes.gain} style={{ color: foreground }}>
                {gain}
              </span>
            </React.Fragment>
          }
          titleTypographyProps={{ color: "inherit" }}
          style={{ color: foreground }}
          action={PeriodSelector}
        />
        {data && (
          <Typography
            className={classes.bigNumber}
            style={{ color: foreground }}
          >
            {bigNumber}
          </Typography>
        )}
        {data && (
          <ResponsiveContainer width="100%" height={150} key={data.length}>
            <LineChart
              data={data}
              margin={{ top: 5, right: 50, left: 50, bottom: 5 }}
            >
              {series.map(s => (
                <Line
                  key={s.property}
                  dataKey={s.property}
                  name={s.name}
                  stroke={s.color}
                  strokeWidth={2}
                  dot={false}
                />
              ))}

              <Tooltip
                cursor={false}
                content={CustomTooltip}
                labelFormatter={labelFormatter}
                formatter={(value, name, _, idx) => (
                  <Typography key={idx}>{format(value)}</Typography>
                )}
              />
              <CartesianGrid
                horizontal={false}
                strokeOpacity={0.3}
                stroke={foreground}
              />
              <YAxis domain={domain} hide />
              <XAxis
                dataKey="effectiveDate"
                stroke={Color(foreground)
                  .alpha(0.8)
                  .string()}
                tickLine={false}
                tickFormatter={labelFormatter}
                minTickGap={100}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
        {!data && (
          <CenteredProgress
            className={classes.progress}
            color="inherit"
            style={{ color: foreground }}
          />
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(FundBalanceChart);
